const CustomButton = ({type}) => {
    const handleDownload = (fileName) => {
        const link = document.createElement('a');
        link.href = process.env.PUBLIC_URL + `/files/${fileName}.pdf`; // Ruta del archivo PDF en la carpeta public
        link.download = `${fileName}.pdf`; // Nombre del archivo que se descargará
        link.click();
    };

    return (
        <>
            {
                type === 'download'
                    ? (
                        <a onClick={() => handleDownload("certificado del material")}
                           className="btn btn-secondary text-white px-5 py-3 rounded-pill">
                            Descargar
                        </a>
                    ) : (
                        <a className="btn btn-secondary text-white px-5 py-3 rounded-pill">
                            Ver Más
                        </a>
                    )

            }
        </>
    )
}

export default CustomButton